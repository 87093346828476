//  import "./Cookies.css";
// import React, { useEffect, useState } from "react";
// import "../../css/style.css";
// import "../../css/responsive.css";
// import "../../css/animate.css";
// import Preloader from "../../shares/Preloader";
// import TitleBread from "../../shares/TitleAndBreadCrumb";
// import { useData } from "../../context/Provider";
// import { fPolicy } from "../../context/api";
// import DynamicMetaTags from "../../components/DynamicMetaTags";

// const API_BASE_URL = process.env.REACT_APP_API_URL;


// const CookiePolicyContnent = () => {    

// const {fHeadingImagesData} = useData()
// const [data, setData] = useState({})
// const Title = "Cookie Policy";
// // const breadTitle = "Coookie Policy";
// useEffect(()=>{
//     fetchData()
// },[])
// const fetchData =  async () =>{
//     try {
//         const formData = {
//             Type:'CookiePolicy'
//         }
//         const response = await fPolicy(formData)
//         setData(response.result)
//         // console.log(response);
//     } catch (error) {
//         console.log(error);
//     }
// }
// return (
//     <>
//                 <DynamicMetaTags page="Cookie" />

//         <Preloader />
//         <div className="cookie-bg centered" style={{ backgroundImage: `url(${`${API_BASE_URL}/assets/${fHeadingImagesData?.Cookie_Image}`})` }}>
//           {/* {console.log('image',`${API_BASE_URL}/assets/${fHeadingImagesData?.Cookie_Image}`)} */}
// </div>
//         {/* <TitleBread Title={Title} breadTitle={breadTitle} image={fHeadingImagesData.Cookie_Image} /> */}
//         <div className="bread-crumb">
//                 <div className="container">
//                     <div className="text">
//                         <a href="/">Home</a>
//                         <i className="fa fa-angle-right"></i> Cookie Policy
//                         {/* <div className="share"><i className="fa fa-share-alt" aria-hidden="true"></i>Share</div> */}
//                     </div>
//                 </div>
//             </div>
//         <div className="container" style={{marginTop:"0px", marginBottom:"50px"}}>
//         <div  
//         style={{
//         color: 'black',
//         fontFamily: 'Raleway',
//         marginBottom: '100px'
//         }}
//         dangerouslySetInnerHTML={{ __html: data?.Message }} />
//             </div>
        
//     </>
// )
// };

// export default CookiePolicyContnent;


import "./Cookies.css";
import React, { useEffect, useState } from "react";
import "../../css/style.css";
import "../../css/responsive.css";
import "../../css/animate.css";
import Preloader from "../../shares/Preloader";
import TitleBread from "../../shares/TitleAndBreadCrumb";
import { useData } from "../../context/Provider";
import { fPolicy } from "../../context/api";
import DynamicMetaTags from "../../components/DynamicMetaTags";

const API_BASE_URL = process.env.REACT_APP_API_URL;

const CookiePolicyContnent = () => {    
    const { fHeadingImagesData } = useData();
    const [data, setData] = useState({});
    const Title = "Cookie Policy";

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const formData = {
                Type: 'CookiePolicy'
            };
            const response = await fPolicy(formData);
            setData(response.result);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <DynamicMetaTags page="Cookie" />
            <Preloader />

            <div 
                className="cookie-bg centered" 
                style={{ 
                    backgroundImage: `url(${`${API_BASE_URL}/assets/${fHeadingImagesData?.Cookie_Image}`})`, 
                    backgroundSize: 'cover', 
                    backgroundPosition: 'center',
                    height: '300px',
                    width: '100%'
                }}>
            </div>

            <div className="bread-crumb">
                <div className="container">
                    <div className="text">
                        <a href="/">Home</a>
                        <i className="fa fa-angle-right"></i> Cookie Policy
                    </div>
                </div>
            </div>

            <div className="container mt-4 mb-5">
                <div className="row">
                    <div className="col-12">
                        <div 
                            style={{
                                color: 'black',
                                fontFamily: 'Raleway',
                                marginBottom: '100px'
                            }}
                            dangerouslySetInnerHTML={{ __html: data?.Message }} 
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default CookiePolicyContnent;
