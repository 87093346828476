/* eslint-disable */

import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faFacebook, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { Link, useNavigate } from "react-router-dom";

const API_BASE_URL = process.env.REACT_APP_API_URL;
const TeamCard = ({ data }) => {
    const navigate = useNavigate();
    return (
        <>
            <section className="team-page centered">
                <div className="container">
                    <div className="row">
                        {data?.map((item, index) => {
                            const formattedName = item?.Name.replace(/\s+/g, '-').toLowerCase();

                            return (
                            <div className="col-md-3 col-sm-6 col-xs-12 team-colmun" key={index} >
                                <Link to={`/team-information/${formattedName}`}>

                                    <div className="single-item" style={{ filter: "contrast(1.0)" }}>

                                        <div className="img-box"><figure><img src={`${API_BASE_URL}/teams/${item.Image}`} alt="" /></figure></div>
                                        <div className="lower-content">
                                            <ul className="team-social">
                                                <li><a href={item.Linkedin} target="_blank" ><FontAwesomeIcon icon={faLinkedinIn} /></a></li>
                                                <li><a href={item.FaceBook} target="_blank" ><FontAwesomeIcon icon={faFacebook} /></a></li>
                                                <li><a href={item.Twitter} target="_blank" ><FontAwesomeIcon icon={faTwitter} /></a></li>
                                            </ul>
                                            <div className="team-info">
                                                <h4>{item?.Name}</h4>
                                                <span>{item?.Designation}</span>
                                                <span>{item?.Designation2}</span>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>

                        )})}
                    </div>
                </div>
            </section>
        </>
    )
}
export default TeamCard;
