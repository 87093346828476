import React, { useEffect } from "react";
import DynamicMetaTags from "../../components/DynamicMetaTags";
import Preloader from "../../shares/Preloader";
import { useData } from "../../context/Provider";
import TeamInfo from "../../components/team-info/team.info";
import { useParams } from "react-router-dom";

const TeamInformation = () => {
    const { Name } = useParams();
    const { fTeamData, fHeadingImagesData, fatchteamData } = useData();
    const API_BASE_URL = process.env.REACT_APP_API_URL;
    const teamImageUrl = `${API_BASE_URL}/assets/${fHeadingImagesData?.Team_Image}`;
    const formattedName = Name.replace(/-/g, ' ');

    useEffect(() => {
        if (!fTeamData || fTeamData.length === 0) {
            fatchteamData();
        }
    }, [fTeamData, fatchteamData]);

    const teamMember = fTeamData?.find(item => item.Name.toLowerCase() === formattedName.toLowerCase());


    return (
        <>
            <DynamicMetaTags page="team" />
            <Preloader />
            <div className="ourTeam-bg centered" style={{ backgroundImage: `url(${teamImageUrl})` }} >
                <div className="container">
                    {/* <div className="text">GET.rx</div> */}
                </div>
            </div>

            <div className="bread-crumb">
                <div className="container">
                    <div className="text"><a href="/">Home</a><i className="fa fa-angle-right"></i> Our Team<i className="fa fa-angle-right"></i> <a href="/">{teamMember?.Name}</a>
                    </div>
                </div>
            </div>
            {teamMember ? <TeamInfo data={teamMember} /> : <p>.</p>}
        </>
    );
};

export default TeamInformation;
