import React, { useEffect } from "react";
import Icon from '../../images/icons/Icon.png';

const GoogleMap = () => {
  useEffect(() => {
    const initializeMap = () => {
      const mapElement = document.getElementById("contact-google-map");
      if (!mapElement) return;

      const mapLat = parseFloat(mapElement.getAttribute("data-map-lat"));
      const mapLng = parseFloat(mapElement.getAttribute("data-map-lng"));
      const iconPath = mapElement.getAttribute("data-icon-path");
      const mapZoom = parseInt(mapElement.getAttribute("data-map-zoom"), 10) || 15;
      const mapTitle = mapElement.getAttribute("data-map-title");
      const markers = JSON.parse(mapElement.getAttribute("data-markers"));

      const styles = [
        {
          featureType: "administrative",
          elementType: "labels.text.fill",
          stylers: [{ color: "#ffffff" }],
        },
        // Add the rest of your styles here...
      ];

      // Init map
      const map = new google.maps.Map(mapElement, {
        center: { lat: mapLat, lng: mapLng },
        zoom: mapZoom,
        styles: styles,
        scrollwheel: false,
      });

      if (iconPath && markers) {
        markers.forEach((marker) => {
          const position = { lat: parseFloat(marker[0]), lng: parseFloat(marker[1]) };
          const contentString = marker[2] || "";

          const markerInstance = new google.maps.Marker({
            position,
            map,
            icon: marker[4] || iconPath,
            title: mapTitle,
          });

          if (contentString) {
            const infoWindow = new google.maps.InfoWindow({
              content: contentString,
            });

            markerInstance.addListener("click", () => {
              infoWindow.open(map, markerInstance);
            });
          }
        });
      }
    };

    const loadGoogleMapsScript = () => {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCRvBPo3-t31YFk588DpMYS6EqKf-oGBSI`;
      script.async = true;
      script.defer = true;
      script.onload = initializeMap;
      script.onerror = () => {
        console.error("Failed to load Google Maps script.");
      };
      document.head.appendChild(script);
    };

    if (typeof google !== "undefined" && google.maps) {
      initializeMap();
    } else {
      loadGoogleMapsScript();
    }
  }, []);

  return (
    <div
      id="contact-google-map"
      className="google-map"
      data-map-lat="-17.76320813546214"
      data-map-lng="31.051105344015355"
      data-map-zoom="15"
      data-markers='[["-17.76320813546214", "31.051105344015355", "InfoWindow content", "icon.png"]]'
      data-icon-path={Icon}  // Ensure you have a valid path to your icon
      data-map-title="GETransfer Pvt Ltd" //-17.76320813546214, 31.051105344015355
    />
  );
};

export default GoogleMap;
