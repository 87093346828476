import React, { useEffect, useState, useRef } from 'react';
import '../../css/Slider.css';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

const API_BASE_URL = process.env.REACT_APP_API_URL;

const CustomSlider = ({ data }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slideRef = useRef(null);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % data.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + data.length) % data.length);
  };

  useEffect(() => {
    const intervalId = setInterval(nextSlide, 5000);
    return () => clearInterval(intervalId);
  }, [data.length]);

  return (
    <div className="slider-container-custom">
      <TransitionGroup>
        {data.map((slide, index) => (
          <CSSTransition
            key={index}
            timeout={500}
            classNames="slide"
            unmountOnExit
          >
            <div
              className={`slide-custom ${index === currentSlide ? 'activeBackground' : ''}`}
              style={{ backgroundImage: `url(${API_BASE_URL}/home/${slide.Image})` }}
            >
              <div className="overlay_background">
                <h1>{slide.Title}</h1>
                <p className="text">{slide.Description}</p>
              </div>
            </div>
          </CSSTransition>
        ))}
      </TransitionGroup>
      <button className="prev" onClick={prevSlide}>
        &lt;
      </button>
      <button className="next" onClick={nextSlide}>
        &gt;
      </button>
    </div>
  );
};

export default CustomSlider;
