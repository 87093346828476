import React, { useEffect, useRef, useState } from "react";
import "../../css/style.css";
import "../../css/responsive.css";
import "../../css/custom/theme-2.css";
import "../../images/favicon.ico";
import "../../css/animate.css";
import Slider from "react-slick";
import { useData } from "../../context/Provider";
import { faTwitter, faLinkedin, faFacebook } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const API_BASE_URL = process.env.REACT_APP_API_URL;

const TeamSection = () => {
    const { experts } = useData();
    const [activeSlide, setActiveSlide] = useState(0);
    const sliderRef = useRef(null);

    useEffect(() => {
        if (sliderRef.current) {
            sliderRef.current.slickGoTo(activeSlide);
        }
    }, [activeSlide]);

    const NextArrow = ({ onClick }) => (
        <button onClick={onClick} className="next-mains">
            <i className="fas fa-chevron-right"></i>
        </button>
    );

    const PrevArrow = ({ onClick }) => (
        <button onClick={onClick} className="prev-mains">
            <i className="fas fa-chevron-left"></i>
        </button>
    );

    const settings = {
        dots: false,
        infinite: experts.length > 1,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        waitForAnimate: false,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: experts.length > 1,
                    dots: true,
                },
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 0,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const handlePagerClick = (index) => {
        setActiveSlide(index);
    };

    return (
        <section className="our-team sec-pad">
            <div className="container">
                <div className="team-title centered">
                    <div className="section-title">
                        <h2>Our Principal Players</h2>
                    </div>
                    <div className="title-text">
                        <p>Exceptional experts drive innovation, strategy, and proven results across industries.</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-10 col-sm-12 col-xs-12 col-md-offset-1 team-colmun">
                        {experts.length > 0 ? (
                            <div className="bxslider team-slider-arrows">
                                <Slider {...settings} ref={sliderRef}>
                                    {experts.map((item, index) => (
                                        <div key={index}>
                                            <div className="row">
                                                <div className="col-md-5 col-sm-5 col-xs-12 team-colmun">
                                                    <div className="clearfix">
                                                        <figure className="img-box pull-left">
                                                            <a href="team"><img src={`${API_BASE_URL}/ourExperts/${item.Image}`} alt="image" /></a>
                                                        </figure>
                                                    </div>
                                                </div>
                                                <div className="col-md-7 col-sm-7 col-xs-12 team-colmun">
                                                    <div className="team-content">
                                                        <div className="team-info">
                                                            <div className="team-text"><a href="team">{item.Name}</a></div>
                                                            <span>{item.Designation}</span>
                                                        </div>
                                                        <div className="text" dangerouslySetInnerHTML={{ __html: item.Description }} />
                                                        <div className="team-contact">
                                                            <div className="text"><strong>Email:</strong>&nbsp; &nbsp;{item.Email}</div>
                                                        </div>
                                                        <ul className="team-social">
                                                            <li><a href={item.twitter_link} target="_blank"><FontAwesomeIcon icon={faTwitter} /></a></li>
                                                            <li><a href={item.linkedin_link} target="_blank"><FontAwesomeIcon icon={faLinkedin} /></a></li>
                                                            <li><a href={item.fb_link} target="_blank"><FontAwesomeIcon icon={faFacebook} /></a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        ) : (
                            <div>Loading...</div>
                        )}
                        {/* <div className="slider-pager">
                            <div className="center">
                                <ul className="nav-link list-inline">
                                    <li id="slider-prev"></li>
                                    <li id="slider-next"></li>
                                </ul>
                            </div>
                        </div> */}
                    </div>
                    <div className="col-md-10 col-sm-12 col-xs-12 col-md-offset-1 team-colmun">
                        <div className="row">
                            <div className="col-md-5">
                                <div className="slider-pager">
                                    <ul className="list-inline thumb-box style2">
                                        {experts.map((item, index) => (
                                            <li key={index}>
                                                <a
                                                    className={index === activeSlide ? 'active' : ''}
                                                    onClick={() => handlePagerClick(index)}
                                                />
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TeamSection;
