// // src/components/CookieConsentModal.js

// import React, { useState, useEffect } from 'react';
// import '../css/CookieConsentModal.css'; // Import the CSS file

// const CookieConsentModal = () => {
//   const [visible, setVisible] = useState(false);
//   const [preferences, setPreferences] = useState({
//     necessary: true,
//     performance: false,
//     functional: false,
//     targeting: false,
//   });

//   useEffect(() => {
//     const consent = localStorage.getItem('cookieConsent');
//     if (!consent) {
//       setVisible(true);
//     }
//   }, []);

//   const handleAcceptAll = () => {
//     localStorage.setItem('cookieConsent', JSON.stringify({
//       necessary: true,
//       performance: true,
//       functional: true,
//       targeting: true,
//     }));
//     setVisible(false);
//   };

//   const handleNecessaryOnly = () => {
//     localStorage.setItem('cookieConsent', JSON.stringify({
//       necessary: true,
//       performance: false,
//       functional: false,
//       targeting: false,
//     }));
//     setVisible(false);
//   };

//   const handleConfirmChoices = () => {
//     localStorage.setItem('cookieConsent', JSON.stringify(preferences));
//     setVisible(false);
//   };

//   const togglePreference = (type) => {
//     setPreferences(prevPreferences => ({
//       ...prevPreferences,
//       [type]: !prevPreferences[type],
//     }));
//   };

//   if (!visible) {
//     return null;
//   }

//   return (
//     <div className="cookie-consent-modal">
//       <div className="cookie-consent-modal-content">
//         <button className="close-button" onClick={() => setVisible(false)}>&times;</button>
//         <h2>Cookie Consent Preference Center</h2>
//         <p>
//           When you visit any of our websites, it may store or retrieve information on your browser,
//           mostly in the form of cookies. This information might be about you, your preferences, or
//           your device and is mostly used to make the site work as you expect it to. The information
//           does not usually directly identify you, but it can give you a more personalized experience.
//           Because we respect your right to privacy, you can choose not to allow some types of cookies.
//           Click on the different category headings to find out more and manage your preferences.
//           Please note, blocking some types of cookies may impact your experience of the site and the
//           services we are able to offer.
//           <a href="/cookie-policy" className="cookie-link"> Cookie Policy</a>.
//         </p>
//         <button onClick={handleAcceptAll} className="cookie-button">Accept all cookies</button>
//         <h3>Manage Consent Preferences</h3>
//         <div className="cookie-preferences">
//           <div className="cookie-preference">
//             <div className="cookie-preference-header">
//               <span>Strictly Necessary Cookies</span>
//               <span className="always-active">Always Active</span>
//             </div>
//             <p>These cookies are necessary for the website to function and cannot be switched off.</p>
//           </div>
//           <div className="cookie-preference">
//             <div className="cookie-preference-header">
//               <span>Performance Cookies</span>
//               <label className="switch">
//                 <input type="checkbox" checked={preferences.performance} onChange={() => togglePreference('performance')} />
//                 <span className="slider round"></span>
//               </label>
//             </div>
//             <p>These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site.</p>
//           </div>
//           <div className="cookie-preference">
//             <div className="cookie-preference-header">
//               <span>Functional Cookies</span>
//               <label className="switch">
//                 <input type="checkbox" checked={preferences.functional} onChange={() => togglePreference('functional')} />
//                 <span className="slider round"></span>
//               </label>
//             </div>
//             <p>These cookies enable the website to provide enhanced functionality and personalization.</p>
//           </div>
//           <div className="cookie-preference">
//             <div className="cookie-preference-header">
//               <span>Targeting Cookies</span>
//               <label className="switch">
//                 <input type="checkbox" checked={preferences.targeting} onChange={() => togglePreference('targeting')} />
//                 <span className="slider round"></span>
//               </label>
//             </div>
//             <p>These cookies may be set through our site by our advertising partners.</p>
//           </div>
//         </div>
//         <div className="cookie-consent-buttons">
//           <button onClick={handleNecessaryOnly} className="cookie-button">Necessary cookies only</button>
//           <button onClick={handleConfirmChoices} className="cookie-button">Confirm my choices</button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default CookieConsentModal;


// src/components/CookieConsentModal.js

import React, { useState, useEffect } from 'react';
import '../css/CookieConsentModal.css'; // Import the CSS file
import cookieIcon from '../images/cookie/icons8-cookie-96.png'; // Ensure this path is correct

// const CookieConsentModal = ({ onClose }) => {
//   const [preferences, setPreferences] = useState({
//     necessary: true,
//     performance: false,
//     functional: false,
//     targeting: false,
//   });

//   useEffect(() => {
//     const consent = localStorage.getItem('cookieConsent');
//     if (consent) {
//       setPreferences(JSON.parse(consent));
//     }
//   }, []);

//   const handleAcceptAll = () => {
//     localStorage.setItem('cookieConsent', JSON.stringify({
//       necessary: true,
//       performance: true,
//       functional: true,
//       targeting: true,
//     }));
//     onClose();
//   };

//   const handleNecessaryOnly = () => {
//     localStorage.setItem('cookieConsent', JSON.stringify({
//       necessary: true,
//       performance: false,
//       functional: false,
//       targeting: false,
//     }));
//     onClose();
//   };

//   const handleConfirmChoices = () => {
//     localStorage.setItem('cookieConsent', JSON.stringify(preferences));
//     onClose();
//   };

//   const togglePreference = (type) => {
//     setPreferences(prevPreferences => ({
//       ...prevPreferences,
//       [type]: !prevPreferences[type],
//     }));
//   };
// import React, { useState, useEffect } from 'react';
// import './CookieConsentModal.css';
// import cookieIcon from './icons8-cookie-96.png';

// const CookieConsentModal = ({ onClose }) => {
//   const [preferences, setPreferences] = useState({
//     necessary: true,
//     performance: false,
//     functional: false,
//     targeting: false,
//   });

//   useEffect(() => {
//     const consent = localStorage.getItem('cookieConsent');
//     if (consent) {
//       setPreferences(JSON.parse(consent));
//     }
//   }, []);

//   const handleAcceptAll = () => {
//     const newPreferences = {
//       necessary: true,
//       performance: true,
//       functional: true,
//       targeting: true,
//     };
//     localStorage.setItem('cookieConsent', JSON.stringify(newPreferences));
//     setPreferences(newPreferences);
//     if (newPreferences.performance) enablePerformanceCookies();
//     onClose();
//   };

//   const handleNecessaryOnly = () => {
//     const newPreferences = {
//       necessary: true,
//       performance: false,
//       functional: false,
//       targeting: false,
//     };
//     localStorage.setItem('cookieConsent', JSON.stringify(newPreferences));
//     setPreferences(newPreferences);
//     onClose();
//   };

//   const handleConfirmChoices = () => {
//     localStorage.setItem('cookieConsent', JSON.stringify(preferences));
//     if (preferences.performance) enablePerformanceCookies();
//     onClose();
//   };

//   const togglePreference = (type) => {
//     setPreferences(prevPreferences => ({
//       ...prevPreferences,
//       [type]: !prevPreferences[type],
//     }));
//   };

//   const enablePerformanceCookies = () => {
//     console.log("Performance cookies enabled");
//   };



const CookieConsentModal = ({ onClose }) => {
    const [preferences, setPreferences] = useState({
      necessary: true,
      performance: false,
      functional: false,
      targeting: false,
    });
  
    useEffect(() => {
      const consent = localStorage.getItem('cookieConsent');
      if (consent) {
        setPreferences(JSON.parse(consent));
      }
    }, []);
  
    const handleAcceptAll = () => {
      const newPreferences = {
        necessary: true,
        performance: true,
        functional: true,
        targeting: true,
      };
      localStorage.setItem('cookieConsent', JSON.stringify(newPreferences));
      setPreferences(newPreferences);
      enableCookies(newPreferences);
      onClose();
    };
  
    const handleNecessaryOnly = () => {
      const newPreferences = {
        necessary: true,
        performance: false,
        functional: false,
        targeting: false,
      };
      localStorage.setItem('cookieConsent', JSON.stringify(newPreferences));
      setPreferences(newPreferences);
      onClose();
    };
  
    const handleConfirmChoices = () => {
      localStorage.setItem('cookieConsent', JSON.stringify(preferences));
      enableCookies(preferences);
      onClose();
    };
  
    const togglePreference = (type) => {
      setPreferences(prevPreferences => ({
        ...prevPreferences,
        [type]: !prevPreferences[type],
      }));
    };
  
    const enableCookies = (prefs) => {
      if (prefs.performance) enablePerformanceCookies();
      if (prefs.functional) enableFunctionalCookies();
      if (prefs.targeting) enableTargetingCookies();
    };
  
    const enablePerformanceCookies = () => {
      console.log("Performance cookies enabled");
    };
  
    const enableFunctionalCookies = () => {
      console.log("Functional cookies enabled");
    };
  
    const enableTargetingCookies = () => {
      console.log("Targeting cookies enabled");
    };

    
  return (
    <div className="cookie-consent-modal">
      <div className="cookie-consent-modal-content">
        <button className="close-button" onClick={onClose}>&times;</button>
        <h2 style={{color:'white', fontSize:'28px', textAlign:'center'}}>Cookie Consent Preference Center</h2>
        <p>
          When you visit any of our websites, it may store or retrieve information on your browser,
          mostly in the form of cookies. This information might be about you, your preferences, or
          your device and is mostly used to make the site work as you expect it to. The information
          does not usually directly identify you, but it can give you a more personalized experience.
          Because we respect your right to privacy, you can choose not to allow some types of cookies.
          Click on the different category headings to find out more and manage your preferences.
          Please note, blocking some types of cookies may impact your experience of the site and the
          services we are able to offer.
          <a href="/cookie-policy" className="cookie-link"> Cookie Policy</a>.
        </p>
        {/* <img src={cookieIcon} alt="cookie icon" className="cookie-icon" /> */}
        <button onClick={handleAcceptAll} className="cookie-button">Accept all cookies</button>
        <br></br>
         <h2 style={{color:'white', fontSize:'21px', textAlign:'center', paddingTop:'20px'}}>Manage Consent Preferences</h2>

        <div className="cookie-preferences">
          <div className="cookie-preference">
            <div className="cookie-preference-header">
              <span style={{color:'white'}}>Strictly Necessary Cookies</span>
              <span className="always-active">Always Active</span>
            </div>
            <p>These cookies are necessary for the website to function and cannot be switched off.</p>
          </div>
          <div className="cookie-preference">
            <div className="cookie-preference-header">
              <span style={{color:'white'}}>Performance Cookies</span>
              <label className="switch">
                <input type="checkbox" checked={preferences.performance} onChange={() => togglePreference('performance')} />
                <span className="slider round"></span>
              </label>
            </div>
            <p>These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site.</p>
          </div>
          <div className="cookie-preference">
            <div className="cookie-preference-header">
              <span style={{color:'white'}} >Functional Cookies</span>
              <label className="switch">
                <input type="checkbox" checked={preferences.functional} onChange={() => togglePreference('functional')} />
                <span className="slider round"></span>
              </label>
            </div>
            <p>These cookies enable the website to provide enhanced functionality and personalization.</p>
          </div>
          <div className="cookie-preference">
            <div className="cookie-preference-header">
              <span style={{color:'white'}} >Targeting Cookies</span>
              <label className="switch">
                <input type="checkbox" checked={preferences.targeting} onChange={() => togglePreference('targeting')} />
                <span className="slider round"></span>
              </label>
            </div>
            <p>These cookies may be set through our site by our advertising partners.</p>
          </div>
        </div>
        <div className="cookie-consent-buttons">
          <button onClick={handleNecessaryOnly} className="cookie-button">Necessary cookies only</button>
          <button onClick={handleConfirmChoices} className="cookie-button">Confirm my choices</button>
        </div>
      </div>
    </div>
  );
};

export default CookieConsentModal;
