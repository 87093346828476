// // src/components/CookieConsent.js

// import React, { useState, useEffect } from 'react';

// const CookieConsent = () => {
//   const [visible, setVisible] = useState(false);

//   useEffect(() => {
//     const consent = localStorage.getItem('cookieConsent');
//     if (!consent) {
//       setVisible(true);
//     }
//   }, []);

//   const handleAcceptAll = () => {
//     localStorage.setItem('cookieConsent', 'all');
//     setVisible(false);
//   };

//   const handleNecessaryOnly = () => {
//     localStorage.setItem('cookieConsent', 'necessary');
//     setVisible(false);
//   };

//   const handleCustomizeSettings = () => {
//     // Handle customization logic here
//     console.log('Customize settings clicked');
//   };

//   if (!visible) {
//     return null;
//   }

//   return (
//     <div style={styles.container}>
//       <div style={styles.content}>
//         <div style={styles.icon}>🍪</div>
//         <div>
//           <p>
//             By clicking “Accept all cookies”, you agree Stack Exchange can store cookies on your
//             device and disclose information in accordance with our
//             <a href="/cookie-policy" style={styles.link}> Cookie Policy</a>.
//           </p>
//         </div>
//       </div>
//       <div style={styles.buttons}>
//         <button onClick={handleAcceptAll} style={styles.button}>Accept all cookies</button>
//         <button onClick={handleNecessaryOnly} style={styles.button}>Necessary cookies only</button>
//         <button onClick={handleCustomizeSettings} style={styles.button}>Customize settings</button>
//       </div>
//     </div>
//   );
// };

// const styles = {
//   container: {
//     position: 'fixed',
//     bottom: 0,
//     left: 0,
//     right: 0,
//     backgroundColor: '#333',
//     color: '#fff',
//     padding: '1em',
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',
//   },
//   content: {
//     display: 'flex',
//     alignItems: 'center',
//     marginBottom: '1em',
//   },
//   icon: {
//     fontSize: '2em',
//     marginRight: '0.5em',
//   },
//   link: {
//     color: '#1a73e8',
//     textDecoration: 'none',
//     marginLeft: '0.5em',
//   },
//   buttons: {
//     display: 'flex',
//     gap: '0.5em',
//   },
//   button: {
//     backgroundColor: '#1a73e8',
//     color: '#fff',
//     border: 'none',
//     padding: '0.5em 1em',
//     cursor: 'pointer',
//     borderRadius: '0.3em',
//   },
// };

// export default CookieConsent;
// src/components/CookieConsent.js

import React, { useState, useEffect } from 'react';
import '../css/CookieConsent.css'; // Import the CSS file
import cookieIcon from '../images/cookie/icons8-cookie-96.png'; // Import the image
import CookieConsentModal from '../components/CookieConsentModal';

const CookieConsent = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (!consent) {
      setVisible(true);
    }
  }, []);


  const [isModalVisible, setModalVisible] = useState(false);

  const handleCustomizeSettings = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };


  const handleAcceptAll = () => {
    localStorage.setItem('cookieConsent', 'all');
    setVisible(false);
  };

  const handleNecessaryOnly = () => {
    localStorage.setItem('cookieConsent', 'necessary');
    setVisible(false);
  };

//   const handleCustomizeSettings = () => {
//     // Handle customization logic here
//     console.log('Customize settings clicked');
//     <CookieConsentModal />
//   };

  if (!visible) {
    return null;
  }

  return (
    <div className="cookie-consent">
      <div className="cookie-consent-content">
      <img src={cookieIcon} alt="cookie icon" className="cookie-icon" style={{filter: 'invert(1) contrast(8.5) drop-shadow(2px 4px 6px black)'}}/>
 
        {/* <div className="cookie-icon">🍪 </div> */}
        <div>
          <p>
            By clicking “Accept all cookies”, you agree Getransfer Pvt Ltd can store cookies on your
            device and disclose information in accordance with our
            <a href="/cookie-policy" className="cookie-link"> Cookie Policy</a>.
          </p>
        </div>
      </div>
       <div className="cookie-consent-buttons">
    

<div className="button-row">
          <button onClick={handleAcceptAll} className="cookie-button">Accept all cookies</button>
          <button onClick={handleNecessaryOnly} className="cookie-button">Necessary cookies only</button>
        </div>

        <button onClick={handleCustomizeSettings} className="cookie-button"> Customize settings </button>
         {isModalVisible && <CookieConsentModal onClose={closeModal} />}

        {/* <button onClick={handleCustomizeSettings} className="cookie-button">Customize settings</button> */}
      </div>
</div>
    
  );
};

export default CookieConsent;
