//import CookiePolicy from './pages/Cookies/Cookies';
import PageRoutes from './routes';
import '@fortawesome/fontawesome-free/css/all.min.css';
import CookieConsent from './components/CookieConsent';
import { Routes, Route } from 'react-router-dom';

// Suppress all console warnings and errors
console.warn = () => {};
console.error = () => {};
function App() {
  return (
    <><div>
      <PageRoutes />
     {/* <CookiePolicy/> */}
     <CookieConsent />
    </div>
    </>
  );
}

//   return (
//     <Routes>
//       <Route exact path="/" element={<Home />} />
//       <Route path="/about-us" element={<AboutUs />} />
//       <Route path="/services" element={<OurServices />} />
//       {/* <Route path="/service/:id?/:Title?" element={<OurServicesDetailsWithId/>}/> */}

//       <Route path="/service/:Page_url?" element={<OurServicesDetailsWithId />} />
//       <Route path="/service" element={<OurServicesDetails />} />
//       <Route path="/team" element={<Team />} />
//       <Route path="/faq" element={<Faqs />} />
//       <Route path="/contact" element={<Contact />} />
//       <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
//       <Route path="/cookie-policy" element={<CookiePolicyContnent />} />
//       <Route path="/sitemap.xml" element={<Sitemap />} />
//       <Route path="*" element={<Error />} />
//       {/* Define other routes as needed */}
//       </Routes>
//   );
// }

export default App;




