import React from "react";
import "../../../css/style.css";
import "../../../css/responsive.css";
import "../../../css/custom/theme-2.css";
import "../../../css/animate.css";
import Preloader from "../../../shares/Preloader";
import TitleBread from "../../../shares/TitleAndBreadCrumb";
import FaqsList from "./FaqsList";
import { useData } from "../../../context/Provider";
import DynamicMetaTags from "../../../components/DynamicMetaTags";
const Faqs = () => {
    const {faqData, fHeadingImagesData} = useData()
    const Title = "FAQ’S";
    const breadTitle = "FAQ’S";
    return (
        <>
            <Preloader />
            <DynamicMetaTags page="faq"/>
            <TitleBread 
            // Title={Title} 
            breadTitle={breadTitle} 
            image={fHeadingImagesData.Faqs_Image} />
            <FaqsList data={faqData.response}/>
        </>
    )
}
export default Faqs