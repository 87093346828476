import React from "react";
import sponsarimg from "../../images/sponsors/1.png"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const API_BASE_URL = process.env.REACT_APP_API_URL;
const Sponsors = ({data})=>{
  //console.log('sponsor-data',data);
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow:5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        nextArrow: <span className="fa fa-angle-right" />,
        prevArrow: <span className="fa fa-angle-left" />,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1 ,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };
    return(
      <section className="sponsors-section">
      <div className="container">
        <div className="sponsors-area">
          <ul className="sponsors-slider">
            <Slider {...settings}>
              {data?.map((item, index) => (
                <li key={index}>
                  <a href={item.sponsor_url} target="_blank">
                    <figure className="sponsor-figure">
                      <img
                        src={`${API_BASE_URL}/sponsors/${item.sponsor_image || "default.png"}`}
                        alt={`Sponsor ${index}`}
                      />
                    </figure>
                  </a>
                </li>
              ))}
            </Slider>
          </ul>
        </div>
      </div>
    </section>
    )
}
export default Sponsors;