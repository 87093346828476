
import React, { useEffect } from 'react';
import "../css/style.css";
import "../css/responsive.css";
// import "../css/custom/theme-2.css";
// import "../css/custom/theme-3.css";
// import "../css/custom/theme-4.css";
// import "../css/custom/theme-5.css";
// import "../css/custom/theme-6.css";
import "../images/favicon.ico";
import "../css/animate.css";


const Preloader = () => {
  const handlePreloader = () => {
    const preloader = document.querySelector('.preloader');
    if (preloader) {
      preloader.style.transition = 'opacity 1s';
      preloader.style.opacity = '0';
      setTimeout(() => {
        preloader.style.display = 'none';
      }, 1000);
    }
  };

  useEffect(() => {
    handlePreloader();
  }, []);

  return <div className="preloader"></div>;
};

export default Preloader;
