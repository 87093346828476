

import React, { useState, useEffect } from "react";
import "../../css/style.css";
import "../../css/responsive.css";
import "../../css/custom/theme-2.css";
import "../../images/favicon.ico";
import "../../css/animate.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter, faFacebook, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

const API_BASE_URL = process.env.REACT_APP_API_URL;

const TeamInfo = ({ data }) => {
    // State to manage loading status
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (data) {
            // Set loading to false when data is received
            setIsLoading(false);
        }
    }, [data]);

    if (isLoading) {
        return <div>Loading...</div>; // Show a loading state while data is being fetched
    }

    return (
        <section className="our-team sec-pad">
            <div className="container">
                <div className="bxslider">
                    <div className="row" style={{ filter: "contrast(1.0)" }}>
                        <div className="col-md-4 col-sm-4 col-xs-12 team-colmun">
                            <div className="clearfix" style={{ width: "100%", height: "auto" }}>
                                <figure className="img-box pull-left" style={{ width: "100%", height: "auto" }}>
                                    <img src={`${API_BASE_URL}/teams/${data?.Image}`} alt="Team member" style={{ width: "100%", height: "auto" }} />
                                </figure>
                            </div>
                        </div>
                        <div className="col-md-8 col-sm-8 col-xs-12 team-colmun">
                            <div className="team-content">
                                <div className="team-info">
                                    <div className="team-text"><a>{data?.Name}</a></div>
                                    <span className="designation-style">{data?.Designation}</span>
                                    {data?.Designation2 && (
                                        <span className="designation-style">{data?.Designation2}</span>
                                    )}
                                </div>
                                <h4 className="description-style" dangerouslySetInnerHTML={{ __html: data?.Description }} />
                                <div className="team-contact">
                                    <div className="text"><strong>Email:</strong>&nbsp; &nbsp;{data?.Email}</div>
                                </div>
                                <ul className="team-social">
                                    <li><a href={data?.Twitter} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faTwitter} /></a></li>
                                    <li><a href={data?.Linkedin} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faLinkedinIn} /></a></li>
                                    <li><a href={data?.Facebook} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faFacebook} /></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TeamInfo;
