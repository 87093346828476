import React, { useEffect, useState } from "react";

import "../../css/style.css";
import "../../css/responsive.css";
import "../../images/favicon.ico";
import "../../css/animate.css";
import { Link, useNavigate } from "react-router-dom";
import Home from "../Home/Home";
import AboutUs from "../AboutsUs/Index";
import OurServices from "../Services/OurServices/OurServices";
import OurServicesDetailsWithId from "../Services/OurServicesDetails/ServicesDetailsWithId";
import OurServicesDetails from "../Services/OurServicesDetails/Index";
import Team from "../UiPages/OurTeam";
import Faqs from "../UiPages/Faqs";
import Contact from "../Contact";
import logo from "../../images/logo/getlogo_black.png"
 import { faRssSquare } from "@fortawesome/free-solid-svg-icons";
import { faTwitter, faFacebook, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const NavBar = () => {
    const navigate = useNavigate();
    const [isScrolled, setIsScrolled] = useState(false);
    const [colorSetting, setColorSetting] = useState(false)
    const [currentLink, setCurrentLink] = useState('/');
    const [isNavbarToggle, setIsNavbarToggle] = useState(false)
    const [isNavbarToggleSticky, setIsNavbarToggleSticky] = useState(false)
    const [isSubMenu, setIsSubMenu] = useState(false)
 

    useEffect(() => {
        const handleScroll = () => {
            const scrollY = window.scrollY;

            setIsScrolled(scrollY > 0);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const headerClasses = `header-lower  ${isScrolled ? 'header-lower-custom' : ''}`;

    const handleOpenClose = () => {
        setColorSetting(!colorSetting)
    }
    const handleColorChange = async (theme) => {
        await import(`../../css/custom/${theme}`);
    };

    useEffect(() => {
        const path = window.location.pathname;
        setCurrentLink(path);

    }, [])

    useEffect(() => {
        const path = window.location.pathname;
        setCurrentLink(path);
        handleColorChange('theme-5.css'); // Set default RED
    }, [])

    const handleToggleNavbar = () => {
        setIsNavbarToggle(!isNavbarToggle)
    }
    const handleToggleSticky = () => {
        setIsNavbarToggleSticky(!isNavbarToggleSticky)
    }

    const hadleSubmenu = () => {
        setIsSubMenu(!isSubMenu)
    }

    function scrollToGetAQuote() {
        const getQuoteSection = document.getElementById('get-a-quote-section');
        if (getQuoteSection) {
            getQuoteSection.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const handleNavigation = (item) => {
        navigate(item)
    }

    return (
        <>
            {/* <!-- main header area --> */}
            <header className="main-header">

                {/* <!-- header upper --> */}
                <div className="header-upper">
                    <div className="container">
                        <div className="top-right">
                            <ul className="social-top">
                                <li><a href="https://www.linkedin.com/company/getransfer"><FontAwesomeIcon icon={faLinkedinIn} /></a></li>
                                <li><a href="https://www.facebook.com/getransfer"><FontAwesomeIcon icon={faFacebook} /></a></li>
                                <li><a href="https://x.com/GeTrx2104"><FontAwesomeIcon icon={faTwitter} /></a></li>
                                <li><a href="/sitemap.xml"><FontAwesomeIcon icon={faRssSquare} /></a></li>
                                {/* <li><a href="#"><i className="fa fa-facebook"></i></a></li>
                                <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                                <li onClick={handleDownload}><a href="#"><i className="fa fa-rss"></i></a></li> */}
                            </ul>
                            <div className="button-top">
                                {/* <a href="/contact" className="btn-one style-one">Get a Quote</a> */}
                                <Link to="/" className="btn-one style-one radi" onClick={scrollToGetAQuote}>Get A Quote</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className={headerClasses}
                //  className="header-lower header-lower-custom"
                // classNam="sticky-header-custom"
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3 col-sm-12 col-xs-12">
                                <div className="logo-continer">
                                    <img src={logo} alt="logo" onClick={() => handleNavigation("/")} />
                                </div>
                            </div>
                            <div className="col-md-9 col-sm-12 col-xs-12">
                                <div className="menu-bar">
                                    <nav className="main-menu">
                                        <div className="navbar-header" onClick={handleToggleNavbar}>
                                            <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                                                <span className="icon-bar"></span>
                                                <span className="icon-bar"></span>
                                                <span className="icon-bar"></span>
                                            </button>
                                        </div>
                                        <div className={`navbar-collapse collapse clearfix ${isNavbarToggle ? "collapse_navbar_open" : ""}`}>
                                            <ul className="navigation clearfix">
                                                <li onClick={() => handleNavigation("/")} className={currentLink === '/' ? 'current' : ''}>
                                                    <a href="/">Home</a>
                                                </li>
                                                <li onClick={() => handleNavigation("/about-us")} className={currentLink === '/about-us' ? 'current' : ''}>
                                                    <a href="/about-us">About Us</a>
                                                </li>

                                                <li onClick={() => handleNavigation("/services")}
                                                    className={currentLink === '/services' || currentLink === '/service' ? 'current dropdown' : 'dropdown'}
                                                ><a href="/services" onClick={hadleSubmenu}>Services</a>
                                                    <ul className={`sub_menu ${isSubMenu ? "SubMenu_open" : ""}`}>
                                                        <li onClick={() => handleNavigation("/services")} ><a href="/services"  >Our Service</a></li>
                                                        <li onClick={() => handleNavigation("/service")}><a href="/service" onClick={() => handleNavigation("/service")}>Service Details</a></li>
                                                    </ul>
                                                </li>
                                                {/* <li
                                                    onClick={() => handleNavigation("/faq")} className={currentLink === '/faq' ? 'current' : ''}
                                                >
                                                    <a href="/faq">FAQ's</a>
                                                </li> */}

                                                <li
                                                    onClick={() => handleNavigation("/team")} className={currentLink === '/team' ? 'current' : ''}
                                                >
                                                    <a href="/team">Our Team</a>
                                                </li>

                                                <li
                                                    onClick={() => handleNavigation("/contact")} className={currentLink === '/contact' ? 'current' : ''}
                                                >
                                                    <a href="/contact" >Contact</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- end header lower --> */}

                {/* <!--sticky header--> */}
                <div
                    className={`sticky-header ${isScrolled ? "sticky-header-custom" : ""}`}
                //   className="sticky-header sticky-header-custom"
                >
                    <div className="container clearfix">
                        <div className="row">
                            <div className="col-md-3 col-sm-12 col-xs-12">
                                <div className="logo-continer">
                                    <img src={logo} alt="logo" onClick={() => handleNavigation("/")} href="/" />
                                </div>
                            </div>
                            <div className="col-md-9 col-sm-12 col-xs-12">
                                <div className="menu-bar">
                                    <nav className="main-menu">
                                        <div className="navbar-header" onClick={handleToggleSticky}>
                                            <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                                                <span className="icon-bar"></span>
                                                <span className="icon-bar"></span>
                                                <span className="icon-bar"></span>
                                            </button>
                                        </div>
                                        <div className={`navbar-collapse collapse clearfix ${isNavbarToggleSticky ? "collapse_navbar_open" : ""}`}>
                                            <ul className="navigation clearfix">
                                                <li onClick={() => handleNavigation("/")} className={currentLink === '/' ? 'current' : ''}><a href="/" >Home</a>
                                                </li>
                                                <li className={currentLink === '/about-us' ? 'current' : ''}><a onClick={() => handleNavigation("/about-us")}  >About Us</a>
                                                </li>
                                                <li onClick={() => handleNavigation("/services")} className={currentLink === '/services' || currentLink === '/service' ? 'current dropdown' : 'dropdown'}><a href="/services" onClick={hadleSubmenu} >Services</a>
                                                    <ul className={`sub_menu ${isSubMenu ? "SubMenu_open" : ""}`}>
                                                        <li onClick={() => handleNavigation("/services")}><a href="/services"  >Our Service</a></li>
                                                        <li onClick={() => handleNavigation("/service")}><a href="/service">Service Details</a></li>
                                                    </ul>
                                                </li>
                                                {/* <li
                                                    onClick={() => handleNavigation("/faq")} className={currentLink === '/faq' ? 'current' : ''}
                                                >
                                                    <a href="/faq"  >FAQ's</a>
                                                </li> */}
                                                <li
                                                    onClick={() => handleNavigation("/team")} className={currentLink === '/team' ? 'current' : ''}
                                                >
                                                    <a href="/team" >Our Team</a>
                                                </li>
                                                <li onClick={() => handleNavigation("/contact")} className={currentLink === '/contact' ? 'current' : ''}>
                                                    <a href="/contact" >Contact</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </nav>
                                    <div className="info-box">
                                        <Link to="/" className="btn-one style-one radi" onClick={scrollToGetAQuote}>Get A Quote</Link>
                                        {/* <div className="button"><a href="#get-a-quote-section" className="btn-one style-one radi">Get A Quote</a></div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- end sticky header --> */}
            </header>
        </>
    )
}

export default NavBar;
