
const API_BASE_URL = process.env.REACT_APP_API_URL;
const META_DATA_PATH = process.env.REACT_APP_METADATA;
const META_DATA_API =  API_BASE_URL + META_DATA_PATH;
//console.log ('META_DATA_API',META_DATA_API);


async function fetchMeta(page, retries = 3) {
 // console.log('fetchUrl', META_DATA_API+`${page}`);
  for (let i = 0; i < retries; i++) {
    try{
      const response = await fetch(META_DATA_API+`${page}`, {

        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    return data.result;
  } catch (error) {
    // console.error('Error fetching metadata:', error);
    // return null;
    console.error(`Attempt ${i + 1} - Error fetching metadata:`, error.message);
    if (i === retries - 1) throw error; // Rethrow error if it's the last attempt

  }
}};

export default fetchMeta;
