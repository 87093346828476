import axios from "axios";
import { param } from "jquery";

const API_BASE_URL = process.env.REACT_APP_API_URL;

// const makeApiRequest = async (url, method, formData) => {
//   try {
//     const response = await axios({
//       method,
//       url: `${API_BASE_URL}${url}`,
//       data: formData,
      
//     });
//     return response.data;
//   } catch (error) {
//     throw error;
//   }
// };

const makeApiRequest = async (url, method, formData = null) => {
  try {
    const response = await axios({
      method,
      url: `${API_BASE_URL}${url}`,
      data: formData,
    });
    return response.data;
    // console.log('makeapi',response);
  } catch (error) {
    console.error('API request error:', error);
    throw error;
  }
};
///***

// async function fetchMeta(page, retries = 3) {
//   for (let i = 0; i < retries; i++) {
//     try {
//       const response = await fetch(`http://localhost:8083/api/v1/getweb/getWebsite/getMetadata/${page}`, {
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//       });

//       if (!response.ok) {
//         throw new Error(`Network response was not ok: ${response.statusText}`);
//       }

//       const data = await response.json();
//       return data.result;
//     } catch (error) {
//       console.error(`Attempt ${i + 1} - Error fetching metadata:`, error.message);
//       if (i === retries - 1) {
//         throw error; // Rethrow error if it's the last attempt
//       }
//     }
//   }
// }


//*/




export const fFaqsDetails = async () => {
    return makeApiRequest("/api/v1/getweb/website/faqdetails", "get");
  };

// export const fInvestors= async () =>{
//     return makeApiRequest("/api/v1/getweb/website/investorsdetails","get")
// }

export const fHomeSlider = async () =>{
  return makeApiRequest("/api/v1/getweb/getWebsite/getHomeSlider", "get")
}

export const fHomeWhyGet = async () =>{
  return makeApiRequest("/api/v1/getweb/getWebsite/getHomeWhyGet", "get")
}

export const fHomeOurServices = async () =>{
  return makeApiRequest("/api/v1/getweb/getWebsite/getHomeOurServices", "get")
}


export const fHomeAllOurServices = async () =>{
  return makeApiRequest("/api/v1/getweb/getWebsite/getHomeAllOurServices", "get")
}

export const fHomeCallBack= async () =>{
  return makeApiRequest("/api/v1/getweb/getWebsite/getHomeCallBack", "get")
}

export const fHomeProject= async () =>{
  return makeApiRequest("/api/v1/getweb/getWebsite/getHomeProject", "get")
}


export const fHeadingImages= async () =>{
  return makeApiRequest("/api/v1/getweb/getWebsite/getHeadingImages", "get")
}


export const fAboutUs= async () =>{
  return makeApiRequest("/api/v1/getweb/getWebsite/getAboutUs", "get")
}

export const fContactUsValues = async () =>{
  return makeApiRequest("/api/v1/getweb/getWebsite/getContactUsValues","get")
}

export const getAQuoteData = async (formData) =>{
  return makeApiRequest("/api/v1/getweb/getWebsite/getAQuoteData","post", formData)
}

// contact Email
export const PContactUsEmail = async (formData) =>{
  return makeApiRequest("/api/v1/getweb/getWebsite/getContactData","post", formData)
}


export const fPolicy = async (formData) =>{
  return makeApiRequest("/api/v1/getweb/getWebsite/getPolicy/","post", formData)
}


export const fTeams = async () =>{
  return makeApiRequest("/api/v1/getweb/getWebsite/getTeams","get",)
}

//get Testimonials
export const fTestimonials = async () =>{
  return makeApiRequest("/api/v1/getweb/getWebsite/getTestimonials","get",)
}

//get experts
export const fExperts = async () =>{
  return makeApiRequest("/api/v1/getweb/getWebsite/getExperts","get",)
}

//get Sponsors

export const fSponsors = async () =>{
  return makeApiRequest("/api/v1/getweb/getWebsite/getSponsors","get",)
}

 

export const subscribeToMailchimp = async (email) => {
  const url = 'https://web.getransfer.co.zw/endPoint/mailChimp';
  const data = { email };
  try {
    const response = await axios.post(url, data);
    console.log('Subscription successful:', response.data);
  } catch (error) {
    console.error('Subscription failed:', error);
  }
};