

import React, { useState } from "react";
import { getAQuoteData } from "../../context/api";
import ReCAPTCHA from "react-google-recaptcha";
const GOOGLE_CAPTCHA_LEY = process.env.GOOGLE_CAPTCHA_LEY || "6LfvlisqAAAAALhh3S4nVfaIVDxdxl_FElZt4s2_";

const CallBackForm = () => {
    const [formData, setFormData] = useState({
        Name: "",
        Email: "",
        Phone: "",
        Type: "",
    });
    const [captchaValue, setCaptchaValue] = useState(null);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleCallBack = async () => {
        try {
            const response = await getAQuoteData(formData);
            alert(response.message);
            setFormData({
                Name: "",
                Email: "",
                Phone: "",
                Type: "",
            });
        } catch (error) {
            console.log(error);
        }
    };

    const handleCaptchaChange = (value) => {
        if (!value) {
            alert('CAPTCHA verification failed, please try again.');
        } else {
            setCaptchaValue(value);
        }
    };

    const handleSubmit = async () => {
        if (!formData.Name || !formData.Email || !formData.Phone || !formData.Type) {
          alert("Please fill in all required fields.");
          return;
        }
        if (!captchaValue) {
          alert("Please complete the CAPTCHA.");
          return;
        }
        await handleCallBack();
      };
      
    

    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     if (!formData.Name || !formData.Email || !formData.Phone || !formData.Type) {
    //         alert("Please fill in all required fields.");
    //         return;
    //     }
    //     if (!captchaValue) {
    //         alert("Please complete the CAPTCHA.");
    //         return;
    //     }
    //     await handleCallBack(); 
    // };
    

    return (
        <div className="form-area">
            {/* <form onSubmit={handleSubmit}> */}
                <div className="row">
                    <div className="col-md-6 col-sm-6 col-xs-12 colmun">
                        <input
                            type="text"
                            placeholder="Your Name"
                            required
                            name="Name"
                            value={formData.Name}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-md-6 col-sm-6 col-xs-12 colmun">
                        <input
                            type="email"
                            placeholder="Email"
                            required
                            name="Email"
                            value={formData.Email}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-md-6 col-sm-6 col-xs-12 colmun">
                        <input
                            type="tel"
                            placeholder="Phone"
                            required
                            name="Phone"
                            value={formData.Phone}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-md-6 col-sm-6 col-xs-12 colmun">
                        <div className="select-box">
                            <select
                                className="custom-select-box"
                                name="Type"
                                value={formData.Type}
                                onChange={handleChange}
                                required
                            >
                                <option hidden>Select a Topic</option>
                                <option value="Investor Relations">Investor Relations</option>
                                <option value="Merchant Relations">Merchant Relations</option>
                                <option value="Feedback">Feedback</option>
                                <option value="Get Appointment">Get Appointment</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-sm-6 col-xs-12 colmun">
                    <div className="form__captch">
                        <ReCAPTCHA
                            // style={{ display: "inline-block" }}
                            theme="dark"
                            // size="normal" 
                            sitekey="6LdxGxcpAAAAALjw0AgB5c73HpxN7at4y9jhZcIK"
                            // sitekey={GOOGLE_CAPTCHA_LEY}
                            onChange={handleCaptchaChange}
                        />
                    </div>
                </div>
                <div className="col-md-6 col-sm-6 col-xs-12 colmun">
                    <div className="button">
                        <button  onClick={handleSubmit} className="btn-one style-one radi">Send Request</button>
                    </div>
                </div>
            {/* </form> */}
        </div>
    );
};

export default CallBackForm;
