import React, { useState } from "react";
import "../../css/style.css";
import "../../css/responsive.css";
import "../../css/custom/theme-2.css";
import "../../css/animate.css";
import GoogleMap from "./Googlemap";
import { PContactUsEmail } from "../../context/api";
import GoogleMapPage from "./GoogleMapPackage";
import ReCAPTCHA from "react-google-recaptcha";

const GOOGLE_CAPTCHA_LEY = process.env.GOOGLE_CAPTCHA_LEY || "6LfvlisqAAAAALhh3S4nVfaIVDxdxl_FElZt4s2_";

const ContactForm = () => {
    const [captchaValue, setCaptchaValue] = useState(null);
    const [formData, setFormData] = useState({
        Name: "",
        Email: "",
        Phone: "",
        Subject: "",
        Message: "",
    })
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };



    const handleCaptchaChange = (value) => {
        if (!value) {
            alert('CAPTCHA verification failed, please try again.');
        } else {
            setCaptchaValue(value);
        }
    };

    const handleSubmit = async () => {
        if (!formData.Name) {
            alert("Name is required.");
            return;
        }

        if (!formData.Email) {
            alert("Email is required.");
            return;
        }

        if (!formData.Phone) {
            alert("Phone is required.");
            return;
        }
        if (!formData.Subject) {
            alert("Phone is required.");
            return;
        }

        if (!captchaValue) {
            alert("Please complete the CAPTCHA.");
            return;
        }
        await handleContact();
    };

    const handleContact = async () => {
        try {
            const response = await PContactUsEmail(formData)
            alert(response.message)
            setFormData({
                Name: "",
                Email: "",
                Phone: "",
                Subject: "",
                Message: "",
            })
        } catch (error) {
            console.log(error);
        }
    }
    return (
        <>

            <section className="contact-section contact-page">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-sm-12 col-xs-12 contact-colmun">
                            <div className="contact-form-area">
                                <div className="title"><h2>Get in Touch</h2></div>
                                <div className="text"><p>Have something on your mind? Let's talk! Contact us for any information or support.</p></div>
                                {/* <form className="default-form" > */}
                                <div className="row">
                                    <div className="col-md-6 col-sm-6 col-xs-12">
                                        <input type="text" name="Name" placeholder="Name" required=""
                                            value={formData.Name} onChange={handleChange} />
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-xs-12">
                                        <input type="email" name="Email" placeholder="Email"
                                            required="" value={formData.Email} onChange={handleChange} />
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-xs-12">
                                        <input type="text" name="Phone" placeholder="Phone" required=""
                                            value={formData.Phone
                                            } onChange={handleChange} />
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-xs-12">
                                        <input type="texxt" name="Subject" placeholder='Subject' required=""
                                            value={formData.Subject} onChange={handleChange} />
                                    </div>
                                    <div className="col-md-12 col-sm-12 col-xs-12">
                                        <textarea placeholder="Message" name="Message" required=""
                                            value={formData.Message} onChange={handleChange} />
                                    </div>
                                </div>
                                <div className="col-md-5 col-sm-5 col-xs-12">
                                    <div className="form__captch">
                                        <ReCAPTCHA
                                            // theme="dark"
                                            sitekey="6LdxGxcpAAAAALjw0AgB5c73HpxN7at4y9jhZcIK"
                                            // sitekey={GOOGLE_CAPTCHA_LEY}
                                            onChange={handleCaptchaChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-1 col-sm-1 col-xs-12">{''}</div>
                                <div className="col-md-6 col-sm-6 col-xs-12">
                                    <button type="submit" className="btn-one style-one radi contactus_button"
                                        onClick={handleSubmit}
                                    >Send Message</button>
                                </div>
                                {/* </form> */}
                            </div>
                        </div>

                        <div className="col-md-6 col-sm-12 col-xs-12 contact-colmun">
                            <div className="google-map-area">
                                <GoogleMap />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default ContactForm