import React from "react";
import Preloader from "../../shares/Preloader";
import TitleBread from "../../shares/TitleAndBreadCrumb";

const Error = () => {
    const Title = "Error Page";
    const breadTitle = "Error Page";
    return (
        <>
            <Preloader />
            <TitleBread Title={Title} breadTitle={breadTitle} />
            <section className="error-section error-page text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-sm-12 col-xs-12 col-md-offset-3 error-colmun">
                            <div className="error-title">404</div>
                            <div className="error-content">
                                <h2 className="section-title">Looks like somthing went wrong</h2>
                                <div className="text">
                                    <p>The page you are looking for was moved, removed, renamed or might never.</p>
                                </div>
                                <div className="input-box">
                                    <form action="#">
                                        <input type="text" placeholder="Search again" />
                                        <button><i className="fa fa-search"></i></button>
                                    </form>
                                </div>
                                <div className="error-btn">
                                    <a href="/" className="btn-one style-one radi">Go Back Home</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Error