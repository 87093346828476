import React, { useEffect, useState } from "react";
import "../../css/style.css";
import "../../css/responsive.css";
import "../../images/favicon.ico";
import "../../css/animate.css";
import axios from "axios";
import { useData } from "../../context/Provider";
import logo from "../../images/logo/footer-logo.png"
import { Link, useNavigate } from "react-router-dom";
import { fHomeAllOurServices } from "../../context/api";
import { faRssSquare } from "@fortawesome/free-solid-svg-icons";
import { faTwitter, faFacebook, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DynamicMetaTags from "../../components/DynamicMetaTags";
import { subscribeToMailchimp } from "../../context/api";
const apiKey = process.env.MAILCHIMP_API_KEY
const audienceId = process.env.MAILCHIMP_AUDIENCE_ID
const Footer = () => {
    const Navigate = useNavigate()
    const { aboutUsData, fHomeAllOurServicesData } = useData();
    const [email, setEmail] = useState('');
    const [data, setData] = useState()

    useEffect(() => { fData() }, []);

    const fData = async () => {
        try {
            const response = await fHomeAllOurServices()
            const shuffledData = shuffleArray(response.result);
            setData(shuffledData);
        } catch (error) {
            console.log(error);
        }
    }

    // const subscribe = async () => {
    //     try {
    //         const response = await axios.post(
    //             `https://<dc>.api.mailchimp.com/3.0/lists/${audienceId}/members`,
    //             {
    //                 email_address: email,
    //                 status: 'subscribed',
    //             },
    //             {
    //                 headers: {
    //                     'Content-Type': 'application/json',
    //                     Authorization: `Basic ${Buffer.from(`apikey:${apiKey}`).toString('base64')}`,
    //                 },
    //             }
    //         );

    //     //    console.log(response.data);
    //         alert(response.data)
    //     } catch (error) {
    //         console.error('Error subscribing to newsletter:', error);
    //     }
    // };

    const [message, setMessage] = useState('');
    const [alert, setAlert] = useState('');
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (email) {
          try {
            await subscribeToMailchimp(email);
          // setMessage('Subscription successful!');
            setAlert('Subscription successful! Welcome aboard and thank you!'); // Clear any previous alert
          } catch (error) {
        //   setAlert('Subscription failed. Please try again.');
        //    setMessage('Oops! Subscription failed. You might already be subscribed or something went wrong.'); // Clear any previous message
        setAlert('Oops! Subscription failed. You might already be subscribed or something went wrong.'); // Clear any previous message
          }
        } else {
          setAlert('Please enter a valid email address.');
          setMessage(''); // Clear any previous message
        }
      };


    const handleAbout = (item) => {
        Navigate(item)
    }
    // const handleClick = Title => {
    //     Navigate(`/service/${encodeURIComponent(fHomeAllOurServicesData.find(item => item.Title === Title).Title.replace(/\s+/g, '-'))}`);
    // };
    const handleClick = Page_url => {
         Navigate(`/service/${fHomeAllOurServicesData.find(item => item.Page_url === Page_url).Page_url}`);
        //setServiceTitle(pageUrl);
        //Navigate(`/service/${pageUrl}`);
        <DynamicMetaTags page={Page_url} />
        event.preventDefault();
        window.location.reload();
    };

    function shuffleArray(array) {
        for (let i = array?.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }

    return (
        <>
            <footer className="main-footer sec-pad">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 col-sm-6 col-xs-12 footer-colmun">
                            <div className="logo-widget footer-widget">
                                <div className="logo-continer">
                                    <img src={logo} alt="logo" />
                                </div>
                                <div className="text">
                                    <p>
                                        {aboutUsData?.Footer_AboutUs}
                                    </p>
                                </div>
                                {/* <div className="button">
                                    <a href="#" className="btn-one style-one radi">Subscribe</a>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6 col-xs-12 footer-colmun">
                            <div className="service-widget footer-widget">
                                <div className="footer-title"><h4>Services</h4></div>
                                <ul className="list">
                                    {shuffleArray(data)?.slice(0, 6).map((item, index) => (
                                        <li key={index}>
                                            <a onClick={() => handleClick(item.Page_url)} >
                                                {item.Title}
                                                {/* <DynamicMetaTags Page_url={item.Page_url}/> */}
                                            </a>
                                        </li>
                                    ))}

                                    {/* <li><a onClick={() => handleAbout("/about-us")}>About Us</a></li>
                                    <li><a  onClick={() => handleAbout("/contact")}>Contact Us</a></li>
                                    <li><a  onClick={() => handleAbout("/services")}>Services</a></li>
                                    <li><a   onClick={() => handleAbout("/terms-conditions")}>Terms & Conditions</a></li> */}
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-6 col-xs-12 footer-colmun">
                            <div className="link-widget footer-widget">
                                <div className="footer-title"><h4>Quick Link</h4></div>
                                <ul className="list">
                                    <li><a onClick={() => handleAbout("/about-us")}>About Us </a></li>
                                    <li><a onClick={() => handleAbout("/contact")}>Contact Us</a></li>
                                    <li><a onClick={() => handleAbout("/services")}>Services</a></li>
                                    <li><a onClick={() => handleAbout("/terms-and-conditions")}>Terms & Conditions</a></li>
                                    <li><a onClick={() => handleAbout("/cookie-policy")}>Cookie Policy</a></li>
                                    <li><a onClick={() => handleAbout("/privacy-policy")}>Privacy Policy</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6 col-xs-12 footer-colmun">
                            <div className="subscribe-widget footer-widget">
                                <div className="footer-title"><h4>Stay Informed! Subscribe Now</h4></div>
                                <div className="text"><p>Stay Updated with Our Latest News & Insights</p></div>
                                <div className="input-box">
                                    {/* <form >
                                        <input type="email" placeholder="Enter your email" required
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)} />
                                        <button onClick={subscribe}><i className="fa fa-paper-plane" aria-hidden="true"></i></button>
                                    </form> */}
                                    <form onSubmit={handleSubmit}>
                                        <input
                                            type="email"
                                            placeholder="Enter your email"
                                            required
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                        {alert && <p style={{ color: 'red' }}>{alert}</p>}
                                        {message && <p style={{ color: 'green' }}>{message}</p>}
                                        <button type="submit">
                                            <i className="fa fa-paper-plane" aria-hidden="true"></i>
                                        </button>
                                    </form>
                                </div>
                                <ul className="footer-social">
                                    {/* <li><a href="#" className="active"><i className="fa fa-facebook"></i></a></li>
                                    <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                                    <li><a href="#"><i className="fa fa-linkedin"></i></a></li> */}
                                 <li><a href="https://www.linkedin.com/company/getransfer"><FontAwesomeIcon icon={faLinkedinIn} /></a></li>
                                <li><a href="https://www.facebook.com/getransfer" ><FontAwesomeIcon icon={faFacebook} /></a></li>
                                <li><a href="https://x.com/GeTrx2104"><FontAwesomeIcon icon={faTwitter} /></a></li>
                                <li><a href="/sitemap.xml"><FontAwesomeIcon icon={faRssSquare} /></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            {/* <!-- main footer area end --> */}

            {/* <!-- footer bottom --> */}
            <section className="footer-bottom centered">
                <div className="container">
                    <div className="copyright"><p><a href="#">GETransfer Pvt Ltd</a> @{new Date().getFullYear()} All Rights Reserved





                    </p></div>
                </div>
            </section>

            {/* <!--End pagewrapper--> */}
        </>
    )
}
export default Footer;
