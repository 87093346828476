import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import fetchMeta from '../context/fetchdata';

const DynamicMetaTags = ({ page }) => {
  const [metadata, setMetadata] = useState(null);

  useEffect(() => {
    const getMetadata = async () => {
      try {
        const data = await fetchMeta(page);
   //     console.log('Fetched metadata:', data); // Log the response
        setMetadata(data);
      } catch (error) {
  //      console.error('Error fetching metadata:', error);
      }
    };

    getMetadata();
  }, [page]);

  if (!metadata) {
    return null; // or a loading spinner
  }

  return (
    <Helmet>
      <title>{metadata.title}</title>
      <meta name="description" content={metadata.description} />
      <meta name="keywords" content={metadata.keywords} />
      <meta name="author" content={metadata.author} />
      <link rel="canonical" href={metadata.canonical_url} />
      <meta name="robots" content={metadata.robots} />
      <meta name="googlebot" content={metadata.googlebot} />
      <meta name="bingbot" content={metadata.bingbot} />
      <meta name="revisit-after" content={metadata.revisit_after} />
      <meta name="rating" content={metadata.rating} />
      <meta property="og:title" content={metadata.og_title} />
      <meta property="og:description" content={metadata.og_description} />
      <meta property="og:type" content={metadata.og_type} />
      <meta property="og:url" content={metadata.og_url} />
      <meta property="og:image" content={metadata.og_image} />
      <meta property="og:site_name" content={metadata.og_site_name} />
      <meta property="og:locale" content={metadata.og_locale} />
      <meta name="twitter:card" content={metadata.twitter_card} />
      <meta name="twitter:title" content={metadata.twitter_title} />
      <meta name="twitter:description" content={metadata.twitter_description} />
      <meta name="twitter:image" content={metadata.twitter_image} />
      <meta name="twitter:site" content={metadata.twitter_site} />
      <meta name="twitter:creator" content={metadata.twitter_creator} />
      <meta name="mobile-web-app-capable" content={metadata.mobile_web_app_capable} />
      <meta name="theme-color" content={metadata.theme_color} />
      <link rel="icon" sizes="192x192" href={metadata.icon_192} />
      <link rel="apple-touch-icon" href={metadata.apple_touch_icon} />
      <meta name="apple-mobile-web-app-capable" content={metadata.apple_mobile_web_app_capable} />
      <meta name="apple-mobile-web-app-status-bar-style" content={metadata.apple_mobile_web_app_status_bar_style} />
      <meta name="apple-mobile-web-app-title" content={metadata.apple_mobile_web_app_title} />
      <meta name="google-site-verification" content={metadata.google_site_verification} />
      <meta name="bing-site-verification" content={metadata.bing_site_verification} />
      <meta name="yandex-site-verification" content={metadata.yandex_site_verification} />
      <meta name="msvalidate.01" content={metadata.msvalidate_01} />
      <meta charSet={metadata.charset} />
      <meta httpEquiv="Content-Language" content={metadata.content_language} />
      <meta name="format-detection" content={metadata.format_detection} />
      <meta httpEquiv="X-UA-Compatible" content={metadata.x_ua_compatible} />
      <meta name="distribution" content={metadata.distribution} />
      <meta name="geo.region" content={metadata.geo_region} />
      <meta name="geo.placename" content={metadata.geo_placename} />
      <meta name="geo.position" content={metadata.geo_position} />
      <meta name="ICBM" content={metadata.icbm} />
    </Helmet>
  );
};

export default DynamicMetaTags;

