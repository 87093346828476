import React from "react";
import "../../css/style.css";
import "../../css/responsive.css";
import "../../css/animate.css";
import "../../images/favicon.ico";

import Preloader from "../../shares/Preloader";
import DynamicMetaTags from "../../components/DynamicMetaTags";

import { useData } from "../../context/Provider";
import CustomSlider from "../../components/Home/CustomSlider";
import Features from "../../components/Home/Features";
import FeaturesCard from "../../components/Home/FeatureCard";
import HomeTestimonials from "../../components/Home/HomeTestimonials";
import CallBack from "../../components/Home/CallBack";
import TeamSection from "../../components/Home/TeamSection";
import Sponsors from "../../components/Home/Sponsors";

const Home = () => {
    const { fHomeSliderData, fHomeWhyGetData, fHomeAllOurServicesData, fHomeOurServicesSection, fHomeCallBackData, sponsors } = useData();
    return (
        <>
            <DynamicMetaTags page="home" />
            <Preloader />
            <CustomSlider data={fHomeSliderData} />
            <Features data={fHomeWhyGetData} />
            <FeaturesCard data={fHomeOurServicesSection} CardData={fHomeAllOurServicesData} />
            <HomeTestimonials />
            <CallBack />
            <TeamSection />
            <Sponsors data={sponsors} />
        </>
    );
};

export default Home;
