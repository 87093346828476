
import React, { useState } from "react";
import styled from 'styled-components';
import { useData } from "../../context/Provider";
import CallBackForm from "./callbackk.form";

const API_BASE_URL = process.env.REACT_APP_API_URL;

const CallBack = () => {
    const { fHomeCallBackData } = useData();

    const CallBackSection = styled.section`
        position: relative;
        width: 100%;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        background-image: ${({ imageUrl }) => `url(${imageUrl})`};
        height: 522px;
        
        &:before {
            position: absolute;
            content: '';
            width: 36%;
            height: 100%;
            top: 0;
            right: 0;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
            background-image: ${({ overlayImageUrl }) => `url(${overlayImageUrl})`};
        }

        &:after {
            position: absolute;
            content: '';
            background: #253559;
            width: 64%;
            height: 100%;
            left: 0;
            top: 0;
            opacity:.9;
        }
    `;

    return (
        <>
            <CallBackSection
                className="call-back sec-pad"
                id="get-a-quote-section"
                imageUrl={`${API_BASE_URL}/home/${fHomeCallBackData?.Banner_image}`}
                overlayImageUrl={`${API_BASE_URL}/home/${fHomeCallBackData?.Image}`}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 col-sm-12 col-xs-12 colmun">
                            <div className="call-back-content">
                                <div className="call-back-title">
                                    <div className="section-title"><h2>Request A <span>Call Back</span></h2></div>
                                    <div className="title-text"><p>We're here to help. Please fill out your details and let us know your interests, and we'll get back to you promptly.</p></div>
                                </div>
                                <CallBackForm />
                            </div>
                        </div>
                    </div>
                </div>
            </CallBackSection>
        </>
    );
};

export default CallBack;
