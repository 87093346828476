import React, { useEffect, useState } from "react";
import "../../css/style.css";
import "../../css/responsive.css";
import "../../css/animate.css";
import Preloader from "../../shares/Preloader";
import TitleBread from "../../shares/TitleAndBreadCrumb";
import { useData } from "../../context/Provider";
import { fPolicy } from "../../context/api";
import DynamicMetaTags from "../../components/DynamicMetaTags";
 
const PrivacyPolicy = () => {
    const {fHeadingImagesData} = useData()
    const [data, setData] = useState({})
    const Title = "Privacy Policy";
    const breadTitle = "Privacy Policy";

    
    useEffect(()=>{
        fetchData()
    },[])
    const fetchData =  async () =>{
        try {
            const formData = {
                Type:'PrivacyPolicy'
            }
            const response = await fPolicy(formData)
            setData(response.result)
             console.log(response);
        } catch (error) {
            console.log(error);
        }
    }
    return (
        <>
                        <DynamicMetaTags page="PrivacyPolicy" />

            <Preloader />
            <TitleBread  breadTitle={breadTitle} image={fHeadingImagesData.Privacy_Image} />
            
            <div className="container" 
            style={{marginTop:"0px", marginBottom:"50px"}}
            >
        <div  
        style={{
        color: 'black',
        fontFamily: 'Raleway',
        marginBottom: '100px'
        }}
        dangerouslySetInnerHTML={{ __html: data?.Message }} />
            </div>
        </>
    )
}
export default PrivacyPolicy;