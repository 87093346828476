import React, { useState, useCallback, useEffect } from 'react';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';

const containerStyle = {
  width: '400px',
  height: '400px'
};

const center = {
  lat: -3.745,
  lng: -38.523
};

function GoogleMapPage() {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyCRvBPo3-t31YFk588DpMYS6EqKf-oGBSI"
  });

  const [map, setMap] = useState(null);

  const onLoad = useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  useEffect(() => {
    if (isLoaded) {
      const bounds = new window.google.maps.LatLngBounds(center);
      const mapInstance = new window.google.maps.Map(document.getElementById("map"), {
        zoom: 10,
        center: center
      });
      mapInstance.fitBounds(bounds);
      setMap(mapInstance);
    }
  }, [isLoaded]);

  return isLoaded ? (
    <div id="map">
      {/* Map will be rendered here */}
    </div>
  ) : null;
}

export default React.memo(GoogleMapPage);
