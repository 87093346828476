import React, { useState } from "react";
import "../../../css/style.css";
import "../../../css/responsive.css";
import "../../../css/custom/theme-2.css";
import "../../../css/animate.css";

const FaqsList = ({ data }) => {
    const [activeIndex, setActiveIndex] = useState(null);

    const handleAccordionClick = (index) => {
        setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    };
    return (
        <>
            <section className="faq-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7 col-sm-10 col-xs-12 faq-colmun">
                            <div className="faq-content">
                                <div className="accordion-box">
                                    {data?.map((item, index) => (
                                        <div
                                            key={index}
                                            className="accordion animated out"
                                            data-delay="0"
                                            data-animation="fadeInUp"
                                        >
                                            <div
                                                className={`acc-btn ${activeIndex === index ? "active" : ""
                                                    }`}
                                                onClick={() => handleAccordionClick(index)}
                                            >
                                                <div className="acc-title">{item.title}</div>
                                                <div className="toggle-icon">
                                                    <i
                                                        className={`plus fa fa-angle-down ${activeIndex === index ? "hide" : ""
                                                            }`}
                                                    ></i>
                                                    <i
                                                        className={`minus fa fa-angle-up ${activeIndex === index ? "" : "hide"
                                                            }`}
                                                    ></i>
                                                </div>
                                            </div>
                                            <div
                                                className={`acc-content ${activeIndex === index ? "collapsed" : ""
                                                    }`}
                                            >
                                                <p>{item.description}</p>
                                            </div>
                                        </div>
                                    ))}

                                </div>
                            </div>
                        </div>
                        <div className="col-md-5 col-sm-8 col-xs-12 faq-colmun">
                            <div className="faq-sidebar">
                                <div className="title"><h4>Ask Questions?</h4></div>
                                <form action="#">
                                    <input type="text" placeholder="First name" />
                                    <input type="email" placeholder="Email Address" />
                                    <textarea placeholder="Message" />
                                    <div className="button"><a href="#" className="btn-one style-one radi">Send Message</a></div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default FaqsList;