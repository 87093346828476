import React from 'react';
import { useRoutes } from 'react-router-dom';
import Home from '../Home/Home';
import OurServicesDetailsWithId from '../Services/OurServicesDetails/ServicesDetailsWithId';
import OurServices from '../Services/OurServices/OurServices';
import AboutUs from '../AboutsUs/Index';
import OurServicesDetails from '../Services/OurServicesDetails/Index';
import Team from '../UiPages/OurTeam';
import Faqs from '../UiPages/Faqs';
import Contact from '../Contact';

const Sitemap = () => {
  const routes = 
    [
        { path: '/', element: <Home /> },
        { path: '/about', element: <AboutUs /> },
        { path: '/services', element: <OurServices /> },
        { path: '/service/:id?', element: <OurServicesDetailsWithId /> },
        { path: '/service', element: <OurServicesDetails /> },
        { path: '/team', element: <Team /> },
        { path: '/faq', element: <Faqs /> },
        { path: '/contact', element: <Contact /> },
    ]
  
  const generateSitemapXML = () => {
    const currentDate = new Date().toISOString().split('T')[0]; 

    const sitemapContent = `
      <?xml version="1.0" encoding="UTF-8"?>
      <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
        <url>
          <loc>https://yourdomain.com/</loc>
          <lastmod>${currentDate}</lastmod>
          <changefreq>monthly</changefreq>
          <priority>1.0</priority>
        </url>
        ${generateRoutesXML()}
      </urlset>
    `;

    return sitemapContent;
  };

  const generateRoutesXML = () => {
        return routes.map((route) => {
            // Add more conditions or customize the route information as needed
            return `
              <url>
                <loc>http://localhost/${route.path}</loc>
                <lastmod>${new Date().toISOString().split('T')[0]}</lastmod>
                <changefreq>monthly</changefreq>
                <priority>0.8</priority>
              </url>
            `;
          }).join('');
        };

  return (
    <>
      {generateSitemapXML()}
    </>
  );
};

export default Sitemap;
