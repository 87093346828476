import React from "react";
import "../css/style.css";
import "../css/responsive.css";
import "../css/custom/theme-2.css";
import "../css/animate.css";
const API_BASE_URL = process.env.REACT_APP_API_URL;
const TitleBread = ({ Title, breadTitle,image }) => {
    return (
        <>
            <div className="terms-bg centered" style={{ backgroundImage: `url(${`${API_BASE_URL}/assets/${image}`})` }}>
                <div className="container">
                    <div className="text">{Title}</div>
                </div>
            </div>

            <div className="bread-crumb">
                <div className="container">
                    <div className="text"><a href="/">Home</a><i className="fa fa-angle-right"></i>{breadTitle}
                        {/* <div className="share"><i className="fa fa-share-alt" aria-hidden="true"></i>Share</div> */}
                    </div>
                </div>
            </div>
        </>
    )
}
export default TitleBread