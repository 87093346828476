// import React, { useRef } from "react";
// import "../../css/style.css";
// import "../../css/responsive.css";
// import "../../css/animate.css";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import styled from 'styled-components';
// import { Link } from "react-router-dom";

// const NextArrow = ({ onClick }) => (
//   <button onClick={onClick}>
//   </button>
// );

// const PrevArrow = ({ onClick }) => (
//   <button onClick={onClick}>
//   </button>
// );


// const API_BASE_URL = process.env.REACT_APP_API_URL;
// const FeaturesCard = ({ data, CardData }) => {
//   const sliderRef = useRef(null);

//   const handlePrev = () => {
//     if (sliderRef.current) {
//       sliderRef.current.slickPrev();
//     }
//   };

//   const handleNext = () => {
//     if (sliderRef.current) {
//       sliderRef.current.slickNext();
//     }
//   };

//   const settings = {
//     dots: false,
//     infinite: true,
//     speed: 300,
//     slidesToShow: 3,
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 2000,
//     nextArrow: <NextArrow />,
//     prevArrow: <PrevArrow />,
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 3,
//           slidesToScroll: 1,
//           infinite: true,
//           dots: true,
//         },
//       },
//       {
//         breakpoint: 800,
//         settings: {
//           slidesToShow: 3,
//           slidesToScroll: 2,
//         },
//       },
//       {
//         breakpoint: 600,
//         settings: {
//           slidesToShow: 2,
//           slidesToScroll: 2,
//         },
//       },
//       {
//         breakpoint: 480,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//         },
//       },
//     ],
//   };

//   const FeaturedSection = styled.section`
//   position: relative; 

//   &:before {
//     position: absolute;
//     content: '';
//     background-image: ${({ overlayImageUrl }) => `url(${overlayImageUrl})`};
//     left: 0px;
//     top: 0px;
//     width: 50%;
//     height: 100%;
//     background-size: cover; 
//     background-repeat: no-repeat;
//   }
// `;

//   return (
//     <>
//       <FeaturedSection
//         className="service-section"
//         overlayImageUrl={`${API_BASE_URL}/home/${data.Image}`}>
//         <div className="container-fullid">
//           <div className="row">
//             <div className="col-md-3 col-sm-6 col-sm-12 service-colmun" >
//               <div className="service-title">
//                 <div className="sec-title"><h2>Our <span>Services</span></h2></div>
//                 <div className="text"><p>{data?.Descrption}</p></div>
//                 <div className="prev-next-main">
//                   <button className="prev" onClick={handlePrev} >
//                     &lt;
//                   </button>
//                   <button className="next" onClick={handleNext} >
//                     &gt;
//                   </button>
//                 </div>
//               </div>

//             </div>
//             <div className="col-md-9 col-sm-12 col-xs-12 service-colmun">
//               <div className="three-column-carousel">
//                 <Slider ref={sliderRef} {...settings}>
//                   {CardData?.map((item, index) => (
//                     //  <Link to={`/service/${item.id}/${encodeURIComponent(item.Title.replace(/\s+/g, '-'))}`} style={{ padding: "20px", margin: "20px" }}>
//                     <Link to={`/service/${item.Page_url}`} style={{ padding: "20px", margin: "20px" }}>
//                       <div style={{ backgroundColor: "silver" }}>
//                         <div className="single-itemsingle-item single-item " key={index} style={{ marginLeft: "30px", fontSize: "16px" }}>
//                         {/* <div style={{ backgroundColor: 'red',height:'400px' }}> */}
//                         <div style={{ height:'400px' }}>


//                           <div className="icon-box">

//                             <a href="service">

//                               {/* <i className="flaticon-graph">
//                               </i>
//                               </a> */}
//                                 <img
//                                   src={`${API_BASE_URL}/ourServices/${item.Icon}`}
//                                   alt={`Sponsor ${index}`}
//                                 />
//                             </a>

//                           </div>
//                           <h4><a href="service"> {item.Title.length > 80 ? `${item.Title.substring(0, 100)}...` : item.Title}</a></h4>
//                           {/* <div style={{ backgroundColor: 'yellow' , height:'300px'}}> */}
//                           <div style={{  height:'300px'}}>

//                             <div className="text"><p>{item.Description.length > 400 ? `${item.Description.substring(0, 400)}...` : item.Description}</p></div>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
                      
//                     </Link>
//                   ))}
//               </Slider>
//             </div>
//           </div>
//         </div>
//       </div>
//     </FeaturedSection >
//       {/* </section> */ }


//   {/*      
//       <section class="service-section">
//         <div class="container-fullid">
//             <div class="row">
//                 <div class="col-md-3 col-sm-6 col-sm-12 service-colmun">
//                     <div class="service-title">
//                         <div class="sec-title"><h2>Our <span>Services</span></h2></div>
//                         <div class="text"><p>Lorem ipsum dolor sit amet consectetur 
//                         adipisicing elit sed do eiusm temp or incididunt</p></div>
//                     </div>
//                 </div>
//                 <div class="col-md-9 col-sm-12 col-xs-12 service-colmun">
//                     <div class="three-column-carousel">
//                         <div class="single-item">
//                             <div class="icon-box"><a href="service.html"><i class="flaticon-graph"></i></a></div>
//                             <h4><a href="service.html">Business Growth</a></h4>
//                             <div class="text"><p>Lorem ipsum dolor sit amet consectetur adipisicing sed do eiusmotempor inc
//                             ididunt ut labore</p></div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     </section>    */}
//     </>
//   )
// }
// export default FeaturesCard



import React, { useRef } from "react";
import Slider from "react-slick";
import styled from 'styled-components';
import { Link } from "react-router-dom";

const NextArrow = ({ onClick }) => (
  <button onClick={onClick} aria-label="Next">
    
  </button>
);

const PrevArrow = ({ onClick }) => (
  <button onClick={onClick} aria-label="Previous">
    
  </button>
);

const FeaturesCard = ({ data, CardData }) => {
  const sliderRef = useRef(null);

  const handlePrev = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const handleNext = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const FeaturedSection = styled.section`
    position: relative;
    &:before {
      position: absolute;
      content: '';
      background-image: ${({ overlayImageUrl }) => `url(${overlayImageUrl})`};
      left: 0;
      top: 0;
      width: 50%;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
    }
  `;

  return (
    <>
    
      <FeaturedSection className="service-section" style={{styleheight:'80%'}} overlayImageUrl={`${process.env.REACT_APP_API_URL}/home/${data.Image}`} >
        <div className="container-fullid">
          <div className="row">
            <div className="col-md-3 col-sm-6 col-sm-12 service-colmun">
              <div className="service-title">
                <div className="sec-title"><h2>Our <span>Services</span></h2></div>
                <div className="text" style={{fontSize:'18px'}}><p>{data?.Descrption}</p></div>
                <div className="prev-next-main">
                  <button className="prev" onClick={handlePrev} aria-label="Previous">&lt;</button>
                  <button className="next" onClick={handleNext} aria-label="Next">&gt;</button>
                </div>
              </div>
            </div>
            <div className="col-md-9 col-sm-12 col-xs-12 service-colmun">
              <div className="three-column-carousel">
                <Slider ref={sliderRef} dots={true} infinite={true} speed={300} slidesToShow={3} slidesToScroll={1} autoplay={true} autoplaySpeed={2000} nextArrow={<NextArrow />} prevArrow={<PrevArrow />} responsive={[
                  {
                    breakpoint: 1024,
                    settings: {
                      slidesToShow: 3,
                      slidesToScroll: 1,
                      infinite: true,
                      dots: true,
                      color:'red',
                    },
                  },
                  {
                    breakpoint: 800,
                    settings: {
                      slidesToShow: 3,
                      slidesToScroll: 2,
                    },
                  },
                  {
                    breakpoint: 600,
                    settings: {
                      slidesToShow: 2,
                      slidesToScroll: 2,
                    },
                  },
                  {
                    breakpoint: 480,
                    settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1,
                    },
                  },
                ]}>
                  {CardData?.map((item, index) => (
                   
                     <div key={index} style={{ padding: "20px", margin: "20px", backgroundColor: "silver", marginLeft: "30px", fontSize: "16px" }}>
                      
                      <Link to={`/service/${item.Page_url}`} style={{ height: '400px', display: 'block' , backgroundColor: "color(srgb 0.8705 0.8706 0.8707)", borderRadius:'10px',} }>
                        <div style={{ height: '400px' , marginLeft:'20px'}}>
                          <div className="icon-box"  style={{paddingLeft:'2px', paddingTop:'10px' }} >
                            <img
                            style={{
                              imageRendering: 'auto',
                              imageRendering: 'crisp-edges', 
                              imageRendering: -'moz-crisp-edges', 
                              iimageRendering: '-o-crisp-edges',
                              imageRendering: '-webkit-optimize-contrast',
                              width:'38px',
                            }}
                            
                            src={`${process.env.REACT_APP_API_URL}/ourServices/${item.Icon}`} alt={`Sponsor ${index}`} />
                          </div>
                          <h4 style={{ color:'red' , filter:'contrast(1.25)'}}>{item.Title.length > 80 ? `${item.Title.substring(0, 100)}...` : item.Title}</h4>
                          <div style={{ height: '300px' }}>
                            <div className="text" style={{width:'260px'}}><p>{item.Description.length > 400 ? `${item.Description.substring(0, 400)}...` : item.Description}</p></div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </FeaturedSection>
    </>
  )
}

export default FeaturesCard;
