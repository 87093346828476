
import React, { useState } from "react";
import "../../css/style.css";
import "../../css/responsive.css";
import "../../images/favicon.ico";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Image1 from '../../images/testimonial/1.png';
import "../../css/animate.css";
import { useData } from "../../context/Provider";
import SliderBar from "../../components/sliderBar";

const API_BASE_URL = process.env.REACT_APP_API_URL;

const HomeTestimonials = () => {
    const { testimonials } = useData();
    const [currentSlide, setCurrentSlide] = useState(0);

    const settings = {
        dots: true,
        customPaging: (i) => (
            <div
                style={{
                    width: "20px",
                    height: "20px",
                    background: i === 0 ? "#fff" : "rgba(255, 255, 255, 0.3)",
                    borderRadius: "10px",
                }}
            />
        ),
        infinite: true,
        speed: 700,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        prevArrow: <span className="fa fa-angle-left" />,
        nextArrow: <span className="fa fa-angle-right" />, 
        afterChange: (current) => setCurrentSlide(current),
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 0,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    return (
        <section className="testimonial-section sec-pad">
            <div className="container">
                <div className="testimonial-title centered">
                    <div className="section-title"><h2>Testimonials</h2></div>
                    <div className="title-text"><p>Discover why our clients trust us for exceptional business solutions</p></div>
                </div>
                <div className="testimonial-slider" style={{padding:"20px"}}>
                    <Slider {...settings}>
                        {testimonials.map((item, index) => (
                            <div key={index} >
                                <div className="testimonial-content">
                                    <div className="authore-info">
                                        <div className="img-box">
                                            <figure><img src={`${API_BASE_URL}/Testimonials/${item.Image}`} alt="" /></figure>
                                        </div>
                                        <div className="author-meta">
                                            <h4>{item.Name}</h4>
                                            <span>{item.Designation}</span>
                                        </div>
                                    </div>
                                    <div className="text"><p>{item.Description}</p></div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                    {/* <SliderBar
                         totalSlides={testimonials.length}
                        currentSlide={currentSlide}
                     //   goToSlide={goToSlide}
                    /> */}
                </div>
            </div>
        </section>
    );
};

export default HomeTestimonials;
